.dso_popup {
	position: absolute;
	background-color: white;
	display: none;
	z-index: 100;
	&.top {
		bottom: 100%;
	}
	&.bottom {
		top: 100%;
	}

	&.top-inset {
		top: 0;
	}
	&.bottom-inset {
		bottom: 0;
	}
	&.right {
		left: 100%;
	}
	&.left {
		right: 100%;
	}
	&.right-inset {
		right: 0;
	}
	&.left-inset {
		left: 0;
	}
	&.open {
		display: unset;
	}
}

.backdrop_popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 99;
	display: none;
	&.open {
		display: unset;
	}
}
