@use "~design_system/src/styles/_variables.scss" as *;

.bg_primary {
    background-color: $primary_300;
}

.card {
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $neutral_0;
    box-shadow: $shadow_soft;
    border-radius: $xxs;
    height: 71px;
    width: 100%;
    padding: 0px;

    @media (min-width: 800) {
        height: 107px;
    }

    &:hover {
        box-shadow: $shadow_medium;
        cursor: pointer;
    }
    &.active {
        background: linear-gradient(90deg, $primary_300 0%, $primary_100 100%);
        &:hover {
            cursor: initial;
        }
    }

    > .card_imageSection {
        display: block;
        position: relative;
        width: 50%;
        height: 100%;
        > .card_image {
            position: absolute;
            top: 0;
            height: 100%;
        }
        > .card_linesImg {
            left: 0;
            z-index: 1;
        }
        > .card_vehicleImg {
            left: 15%;
            z-index: 2;
        }
    }
}
