@use "~design_system/src/styles/_variables.scss" as *;

.custom-slider {
  align-self: center;
  .slider-point {
    display: inline-flex;
    height: 0.625rem;
    width: 0.625rem;
    background-color: $neutral_500;
    border-radius: 0.3125rem;
    margin-right: var(--xs);
    transition: 0.5s;
  }
  .slider-point.active {
    width: 1.9375rem;
    background-color: #fe8a02;
  }
}
