@use "~design_system/src/styles/_variables.scss" as *;
.media-list-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  justify-content: center;
  @media (max-width: $dso_bp_desktop) {
    justify-content: flex-start;
  }
}
