.checksIncomes {
    .checksIncomes-card {
        flex-grow: 1;
        text-align: center;

        .checksIncomes-icon {
            width: 50px;
            height: 30px;
            stroke-width: 0;
        }
    }
}
