.root {
  display: flex; 
  flex-direction: column;
  min-height: 100vh; 
  overflow-x: hidden;
  .main {
    flex: 1;
    width: 100vw;
    position: relative;
    display: flex; 
    flex-direction: column; 
  }

  .bordeNav {
    margin-bottom: 4.3rem;
  }

  @media screen and (max-width: 600px) {
    .bordeNav {
      margin-bottom: 3.1rem;
    }
  }

  .layout-container {
    flex: 1; 
    margin: 0 auto; 
    width: 100%; 
  }
}
.fab-wa {
  background-color: var(--green_300);
  fill: white;
  bottom: var(--xl);
  left: var(--xl);
  z-index: 50;
  border-radius: 50%;
  position: fixed;
  display: flex;
}
