.stepperItem.with_description {
    margin-top: -10px;
}

.stepperItem__container.with_opacity {
    opacity: .6;
}

.stepperItem__separator.with_description {
    margin: -10px 0px 0px 0px;
}

.stepperItem__btn.enabled {
    &:hover {
        cursor: pointer;
    }
}
