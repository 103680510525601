.MuiPopover-paper {
	padding-bottom: 0.625rem;
	& .MuiPickersBasePicker-pickerView {
		padding-bottom: 1.25rem;
	}
}
.MuiPickersBasePicker-container {
	.MuiTypography-body1 {
		font-family: 'Poppins';
		font-size: 1.25rem;
		font-weight: 600;
		line-height: var(--xl);
		text-align: center;
	}

	.MuiPickersCalendarHeader-switchHeader {
		justify-content: center;
		.MuiPickersCalendarHeader-transitionContainer {
			width: fit-content;
			height: var(--xl);
			p {
				position: relative;
				width: fit-content;
			}
		}
	}

	.MuiPickersDay-day {
		border-radius: var(--xs);
		// height: var(--dso_space_xxl);
		// width: var(--dso_space_xxl);
		margin-bottom: var(--xxs);
		border: var(--xxxs) solid var(--gray_400);
		p {
			font-family: 'Poppins';
			font-size: 1.25rem;
			font-weight: 600;
			line-height: var(--xl);
			color: var(--gray_700);
		}
		&.MuiPickersDay-daySelected {
			background-color: var(--primary_300);
			p {
				color: white;
			}
		}
		&.MuiPickersDay-dayDisabled {
			p {
				color: var(--gray_500);
			}
		}
	}
}
