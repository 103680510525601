.pager-cont {
	.pager_arrows {
		border: 0.25px solid var(--neutral_600);
		cursor: pointer;
		display: flex;
	}
	.pages-cont {
		max-width: calc(var(--xxl) * 10);
		overflow-x: auto;
	}
	.pager_numbers {
		color: var(--neutral_700);
		cursor: pointer;
		&.active {
			background-color: var(--primary_300);
			color: white;
		}
	}
}
