.dso_weekdays {
	display: flex;
	border-bottom: 1px solid var(--neutral_400);
	gap: var(--xxs);
}
.dso_days {
	display: flex;
	width: calc((#{var(--xl)} * 7) + (#{var(--xxs)} * 6));
	flex-wrap: wrap;
	gap: var(--xxs);
	padding: var(--xs) 0;
	.dso_day {
		border: 2px var(--neutral_400) solid;
		border-radius: var(--xs);
		color: var(--neutral_700);
		cursor: pointer;
		&:hover,
		&.hover {
			background-color: var(--primary_100);
		}
		&.selected {
			background-color: var(--primary_300);
			color: white;
		}
		&.current {
			border-color: var(--primary_200);
		}
	}
}
