@use '../../styles/variables' as *;

.slider-container {
	& .MuiSlider-thumb {
		background: $primary_300;
		&:hover,
		&.Mui-active,
		&.Mui-focusVisible {
			box-shadow: none;
		}
	}
	& .MuiSlider-rail {
		height: 8px;
		border: 1px solid $neutral_500;
		background: white;
	}
	& .MuiSlider-track {
		background: $primary_300;
		border: none;
	}
	& .MuiSlider-mark {
		background: $primary_300;
	}

	& .MuiSlider-valueLabel {
		@media (min-width: $dso_bp_desktop) {
		}
	}

	& .MuiSlider-markLabel {
	}
}
