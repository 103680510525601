@use "~design_system/src/styles/_variables.scss" as *;

.vehicle-card-placeholder-container {
  width: 100%;
  height: 460px;
  max-width: 328px;
  @media (max-width: $dso_bp_desktop) {
    height: 316.58px;
    max-width: 159.82px;
  }
  display: flex;
  flex-direction: column;

  & .card-header {
    justify-content: space-between;
    @media (max-width: $dso_bp_desktop) {
      flex-direction: column;
    }
  }

  & .row {
    display: flex;
  }
}

.vehicle-card-placeholder-container-small {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 108px;

  & > .dso_card {
    width: 100%;
    height: 108px;
  }
}
