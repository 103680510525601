.tag-cont {
	border: solid 1px var(--neutral_400);
	.icon {
		display: flex;
		height: fit-content;
		> * {
			width: 1.125rem;
			height: 1.125rem;
		}
	}
	.content {
		border-width: 1px;
	}
}
