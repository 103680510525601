.option_date {
	position: relative;
	white-space: nowrap;
	&:hover {
		background-color: var(--primary_100);
	}
	&.selected {
		background-color: var(--primary_100);
		padding-left: var(--xxl);
		.check_icon {
			opacity: 1;
		}
	}
	.check_icon {
		position: absolute;
		opacity: 0;
		top: 0;
		left: 0;
	}
}
.popup_date {
	bottom: 100% !important;
	right: 0 !important;
	left: unset !important;
}
