@use '../../../styles/variables' as *;

.stepper-steps {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	transition: transform ease-out 0.3s;

	&.swiping {
		pointer-events: none;
		cursor: grabbing;
	}
}
