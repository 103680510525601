.navbar {
  background-color: var(--neutral_0);
  grid-area: head;
  width: 100%;
  z-index: 100000;
  display: flex;
  .main-container {
    padding: var(--md) var(--xl);
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menu-button {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .buttonPre {
    padding-top: 2px!important;
    padding-bottom: 2px!important;
  }
  .backdrop {
    position: fixed;
    z-index: 999;
    background: rgba(0, 0, 0, 0.64);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 250ms;
    pointer-events: none;
    &.open {
      pointer-events: unset;
      opacity: 1;
      .sidebar {
        right: 0;
      }
    }
    .sidebar {
      position: absolute;
      width: 250px;
      height: 100%;
      right: -250px;
      top: 0;
      background: white;
      transition: 500ms;
    }
  }
}
.navbar-micuenta {
  background-color: var(--primary_300);
  width: 100%;
  height: 40px;
  z-index: 100000;
  display: flex;
}
.my-swal {
  z-index: 200000 !important;
}
