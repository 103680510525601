@use "~design_system/src/styles/_variables.scss" as *;


.image_preview {
    width: 100%;
    height: 9rem;
    position: relative;
    overflow: hidden;
    &.fill{
        border: 0.25rem solid $primary_300;
    }
    img{
        min-height: 100%;
        min-width: 100%;
        width: 0;
    }
}

.loading {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(250, 250, 250, 0.4);
}

.loading-icon {
    color: $primary_300 !important;
}

.image-preview-close {
    position: absolute !important;
    top: calc($md * -1);
    right: calc($md * -1);
}

.tooltip_obj{
    position: absolute;
    left: 105%;
    top:50%;
    transform: translateY(-50%);
}