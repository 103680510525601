.CallToAction_section {
    max-width: 500px;
}

@media (max-width: 900px) {
    .CallToAction_btn {
        cursor: pointer;
        & svg {
            transition: all ease 0.1s;
            transform: rotate(-90deg);
        }
        &:hover {
            & svg {
                transform: rotate(-90deg) translateX(5px);
            }
        }
        &.rotate_down {
            & svg {
                transition: all ease 0.1s;
                transform: rotate(90deg);
            }
            &:hover {
                & svg {
                    transform: rotate(90deg) translateX(5px);
                }
            }
        }
    }
}

.CallToAction-animation {
    position: relative;

    & .CallToAction-animation__image {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        left: 0;
        right: 0;
        z-index: -1;

        &.CallToAction-animation__image--1 {
            animation: rotateImages1 12s infinite;
        }
        &.CallToAction-animation__image--2 {
            animation: rotateImages2 12s infinite;
        }
        &.CallToAction-animation__image--3 {
            animation: rotateImages3 12s infinite;
        }
        &.CallToAction-animation__image--4 {
            animation: rotateImages4 12s infinite;
        }
        &.CallToAction-animation__image--5 {
            animation: rotateImages5 12s infinite;
        }
        &.CallToAction-animation__image--6 {
            animation: rotateImages6 12s infinite;
        }
        &.CallToAction-animation__image--7 {
            animation: rotateImages7 12s infinite;
        }
        &.CallToAction-animation__image--8 {
            animation: rotateImages8 12s infinite;
        }
    }
}

@keyframes rotateImages1 {
    0% { opacity: 1; }
    6.5% { opacity: 1; }
    12.5% { opacity: 0; }
    94% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes rotateImages2 {
    0% { opacity: 0; }
    6.5% { opacity: 0; }
    12.5% { opacity: 1; }
    19% { opacity: 1; }
    25% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes rotateImages3 {
    0% { opacity: 0; }
    19% { opacity: 0; }
    25% { opacity: 1; }
    31.5% { opacity: 1; }
    37.5% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes rotateImages4 {
    0% { opacity: 0; }
    31.5% { opacity: 0; }
    37.5% { opacity: 1; }
    44% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes rotateImages5 {
    0% { opacity: 0; }
    44% { opacity: 0; }
    50% { opacity: 1; }
    56.5% { opacity: 1; }
    62.5% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes rotateImages6 {
    0% { opacity: 0; }
    56.5% { opacity: 0; }
    62.5% { opacity: 1; }
    69% { opacity: 1; }
    75% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes rotateImages7 {
    0% { opacity: 0; }
    69% { opacity: 0; }
    75% { opacity: 1; }
    81.5% { opacity: 1; }
    87.5% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes rotateImages8 {
    0% { opacity: 0; }
    81.5% { opacity: 0; }
    87.5% { opacity: 1; }
    94% { opacity: 1; }
    100% { opacity: 0; }
}
