.ozon-table-head {
	th:first-child {
		border-top-left-radius: var(--xxs);
		border-bottom-left-radius: var(--xxs);
	}
	th:last-child {
		border-top-right-radius: var(--xxs);
		border-bottom-right-radius: var(--xxs);
	}
}
