html {
	font-size: 12px;
}
@media screen and (min-width: 250px) {
	html {
		font-size: 13px;
	}
}
@media screen and (min-width: 350px) {
	html {
		font-size: 14px;
	}
}
@media screen and (min-width: 450px) {
	html {
		font-size: 15px;
	}
}
@media screen and (min-width: 500px) {
	html {
		font-size: 15.5px;
	}
}
@media screen and (min-width: 550px) {
	html {
		font-size: 16px;
	}
}

@media screen and (min-width: 600px) {
	html {
		font-size: 11.5px;
	}
}
@media screen and (min-width: 600px) and (min-aspect-ratio: 25/12) {
	html {
		font-size: calc(11.5px - 2px);
	}
}
@media screen and (min-width: 800px) {
	html {
		font-size: 10px;
	}
}
@media screen and (min-width: 800px) and (min-aspect-ratio: 25/12) {
	html {
		font-size: calc(10px - 2px);
	}
}
@media screen and (min-width: 900px) {
	html {
		font-size: 11px;
	}
}
@media screen and (min-width: 900px) and (min-aspect-ratio: 25/12) {
	html {
		font-size: calc(11px - 2px);
	}
}
@media screen and (min-width: 1000px) {
	html {
		font-size: 12px;
	}
}
@media screen and (min-width: 1000px) and (min-aspect-ratio: 25/12) {
	html {
		font-size: calc(12px - 2px);
	}
}
@media screen and (min-width: 1100px) {
	html {
		font-size: 13px;
	}
}
@media screen and (min-width: 1100px) and (min-aspect-ratio: 25/12) {
	html {
		font-size: calc(13px - 2px);
	}
}
@media screen and (min-width: 1200px) {
	html {
		font-size: 14px;
	}
}
@media screen and (min-width: 1200px) and (min-aspect-ratio: 25/12) {
	html {
		font-size: calc(14px - 2px);
	}
}
@media screen and (min-width: 1300px) {
	html {
		font-size: 15px;
	}
}
@media screen and (min-width: 1300px) and (min-aspect-ratio: 25/12) {
	html {
		font-size: calc(15px - 2px);
	}
}
@media screen and (min-width: 1400px) {
	html {
		font-size: 16px;
	}
}
@media screen and (min-width: 1400px) and (min-aspect-ratio: 25/12) {
	html {
		font-size: calc(16px - 2px);
	}
}
@media screen and (min-width: 1400px) {
	html {
		font-size: 16px;
	}
}
@media screen and (min-width: 1400px) and (min-aspect-ratio: 25/12) {
	html {
		font-size: calc(16px - 2px);
	}
}
