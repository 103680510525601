.dso_counter {
	position: relative;
	.dso_indicator_left {
		position: absolute;
		left: var(--sm);
		bottom: calc(var(--md) + var(--sm) / 2);
		transform: translateY(50%);
	}
	.dso_indicator_right {
		position: absolute;
		right: var(--sm);
		bottom: calc(var(--md) + var(--sm) / 2);
		transform: translateY(50%);
	}
}
