@use "~design_system/src/styles/_variables.scss" as *;

.modal_terms {
  padding: 0px !important;
  min-width: 800px;
  min-height: 500px;

  @media (max-width: $dso_bp_desktop) {
    min-width: 85%;
  }
}

.modal_terms::-webkit-scrollbar {
  display: none;
}

.modal_content {
  overflow-y: auto;
  max-height: 400px;
}
