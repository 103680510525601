@use '../../../styles/variables' as *;

.stepper-count-list {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;

	& .container {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	& .checkmark-container {
		width: 24px;
		height: 24px;
		border: 2px solid;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2px;
		& svg {
			fill: $neutral_700;
			color: $neutral_700;
		}
		& svg.completed {
			fill: $primary_300;
			color: $primary_300;
		}
	}
}
