.modalcontainer {
	position: fixed;
	z-index: 100001;
	display: none;
	justify-content: center;

	align-items: center;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	& .modal {
		overflow-y: scroll;
		z-index: 100002;
		transform: translateY(-1000px);
		transition: transform 2s;
		width: 448px;
		max-height: 90vh;
	}
	&.open {
		display: flex;
		& .modal {
			transform: translateY(0px);
		}
	}
	& .backdrop {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background-color: black;
		opacity: 0.4;
	}

	.modal {
		& .button {
			right: 1%;
			top: 2%;
			position: fixed;
		}
	}
}
