.SideModal {
	position: fixed;
	top: 0;
	height: 100vh;
	transition: 0.25s;
	transition-property: right;
	opacity: 0;
	pointer-events: none;
	z-index: 99;
	&.large {
		width: 100vw;
		right: -100vw;
		&.open {
			right: -16.66vw;
			.close_modal {
				right: calc(16.66vw + var(--md));
			}
		}
	}
	&.medium {
		right: -100vw;
		width: 100vw;
		&.open {
			right: -33.333vw;
			.close_modal {
				right: calc(33.333vw + var(--md));
			}
		}
	}
	&.normal {
		right: -80vw;
		width: 80vw;
		&.open {
			right: -40vw;
			.close_modal {
				right: calc(40vw + var(--md));
			}
		}
	}

	&.small {
		right: -50vw;
		width: 50vw;
		&.open {
			right: -25vw;
			.close_modal {
				right: calc(25vw + var(--md));
			}
		}
	}

	.backdrop {
		position: absolute;
		height: 100%;
		width: 110vw;
		top: 0;
		right: 95%;
		background-color: var(--neutral_1000);
		opacity: 0.4;
		z-index: 0;
	}
	.main-cont {
		z-index: 1;
		background: var(--neutral_100);
		position: absolute;
		height: 100%;
	}
	&.full-open {
		right: 0 !important;
		.close_modal {
			right: var(--md) !important;
		}
	}
	&.open {
		pointer-events: all;
		opacity: 1;
	}
	.close_modal {
		position: absolute;
		top: var(--md);
	}
}
