@use '../../styles/variables' as *;
@mixin dso_btn {
	border: $xxs solid transparent;
	border-radius: $xs;
	outline: none;
	position: relative;
	transition: 0.125s;
	white-space: nowrap;
	padding: 0;
	cursor: pointer;
	padding: $sm $md;
	.dso_btn_cont {
		width: fit-content;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		transition: inherit;
		bottom: 0;
		.dso_btn_text {
			width: fit-content;
			transition: 0.125s;
			line-height: $xl;
			font-size: $lg;
			font-weight: 800;
		}
		.dso_btn_img {
			transition: 0.125s;
			position: absolute;
			top: 50%;
			height: $lg;
			width: $lg;
			transform: translateY(-50%);
			display: none;
		}
	}
	&:hover {
		.dso_btn_cont {
			bottom: $xxs;
		}
	}
	&.disabled,
	&:disabled {
		border: none;
		background-color: $neutral_500 !important;
		color: $neutral_600 !important;
		fill: $neutral_600 !important;
		cursor: not-allowed;
	}
	&.dso_btn_small {
		border-width: $xxxs;
		border-radius: $xxs;
		padding: 0.625rem $sm;
		.dso_btn_cont {
			.dso_btn_text {
				line-height: $md;
				font-size: $sm;
			}
		}
		&:hover {
			.dso_btn_cont {
				bottom: 0.09rem;
			}
		}
	}

	&.dso_btn_imgl {
		.dso_btn_cont {
			.dso_btn_text {
				padding-left: $xxl;
			}
			.dso_btn_img {
				display: unset;
				left: 0;
			}
		}
	}
	&.dso_btn_imgr {
		.dso_btn_cont {
			.dso_btn_text {
				padding-right: $xxl;
			}
			.dso_btn_img {
				display: unset;
				right: 0;
			}
		}
	}
	&.dso_btn_extend {
		.dso_btn_cont {
			width: 100%;
			.dso_btn_text {
				width: 100%;
			}
		}
		&.dso_btn_imgr .dso_btn_text {
			text-align: left;
		}
		&.dso_btn_imgl .dso_btn_text {
			text-align: right;
		}
	}
}

.dso_btn_img > * {
	height: 100%;
	width: 100%;
}

$color_name: 'primary', 'primary_dark', 'secondary', 'green', 'red', 'neutral',
	'white', 'grey', 'light_grey', 'gradient';
$color: $primary_300, $primary_500, $secondary_600, $green_400, $red_300,
	$neutral_900, $neutral_0, $neutral_700, $neutral_300, $primary_gradient;
$contrast: $primary_contrast_300, $primary_contrast_500, $secondary_contrast_600,
	$green_contrast_400, $red_contrast_300, $neutral_contrast_900,
	$neutral_contrast_0, $neutral_contrast_700, $neutral_contrast_300,
	$primary_contrast_gradient;
$hover: $primary_200, $primary_400, $secondary_400, $green_500, $red_400,
	$neutral_700, $neutral_0, $neutral_500, $neutral_200, $primary_gradient;
$tint: $primary_25, $primary_50, $secondary_100, $green_100, $red_100,
	$neutral_500, $neutral_0, $neutral_300, $neutral_100, $primary_gradient;
.dso_btn_principal {
	@include dso_btn;
	box-shadow: $shadow_medium;
	@each $name in $color_name {
		$i: index($color_name, $name);
		&.dso_btn_color_#{$name} {
			background-color: nth($color, $i);
			color: nth($contrast, $i);
			fill: nth($contrast, $i);
		}
	}
}
.dso_btn_tint {
	@include dso_btn;
	box-shadow: $shadow_medium;
	@each $name in $color_name {
		$i: index($color_name, $name);
		&.dso_btn_color_#{$name} {
			background-color: nth($tint, $i);
			color: nth($color, $i);
			fill: nth($color, $i);
		}
	}
}
.dso_btn_outline {
	@include dso_btn;
	background: transparent;
	@each $name in $color_name {
		$i: index($color_name, $name);
		&.dso_btn_color_#{$name} {
			border-color: nth($color, $i);
			color: nth($color, $i);
			fill: nth($color, $i);
			&.active {
				background-color: nth($hover, $i);
			}
		}
	}
	&.active {
		border-color: transparent;
		color: white;
		fill: white;
		.dso_btn_cont {
			bottom: 0;
		}
	}
}
.dso_btn_ghost {
	@include dso_btn;
	background-color: transparent;
	@each $name in $color_name {
		$i: index($color_name, $name);
		&.dso_btn_color_#{$name} {
			color: nth($color, $i);
			fill: nth($color, $i);
		}
	}
}
.dso_btn_link {
	@include dso_btn;
	color: $neutral_700;
	fill: $neutral_700;
	background-color: transparent;
	&.active {
		color: $primary_300;
		fill: $primary_300;
		.dso_btn_cont {
			bottom: $xxs;
		}
	}
}

.dso_btn_selector {
	cursor: pointer;
	padding: 0;
	border-radius: $xs;
	border: none;
	outline: none;
	position: relative;
	transition: 0.125s;
	white-space: nowrap;
	background: $neutral_600;
	color: $neutral_900;
	fill: $neutral_900;
	padding: 0.625rem $md;
	.dso_btn_cont {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		transition: inherit;
		bottom: 0;
		width: fit-content;
		.dso_btn_text {
			transition: 0.125s;
			line-height: 1.25rem;
			font-size: 0.875rem;
			font-weight: 800;
		}
		.dso_btn_img {
			transition: 0.125s;
			position: absolute;
			top: 50%;
			height: $lg;
			width: $lg;
			transform: translateY(-50%);
			display: none;
		}
	}
	&.dso_btn_imgl {
		.dso_btn_cont {
			.dso_btn_text {
				padding-left: $xxl;
			}
			.dso_btn_img {
				display: unset;
				left: 0;
			}
		}
	}
	&.dso_btn_imgr {
		.dso_btn_cont {
			.dso_btn_text {
				padding-right: $xxl;
			}
			.dso_btn_img {
				display: unset;
				right: 0;
			}
		}
	}
	&.active {
		@each $name in $color_name {
			$i: index($color_name, $name);
			&.dso_btn_color_#{$name} {
				background-color: nth($color, $i);
				color: nth($contrast, $i);
				fill: nth($contrast, $i);
			}
		}
	}
}
.dso_btn_icon {
	cursor: pointer;
	padding: 0;
	border-radius: $md;
	outline: none;
	position: relative;
	transition: 0.125s;
	white-space: nowrap;
	background: $neutral_200;
	color: $neutral_700;
	fill: $neutral_700;
	border: $xxs solid $neutral_500;
	&.dso_btn_small {
		&.dso_btn_icon_fill {
			.dso_btn_cont {
				.dso_btn_img {
					height: $lg;
					width: $lg;
				}
			}
		}
		&.dso_btn_icon_edit {
			.dso_btn_cont {
				.dso_btn_img {
					height: $md;
					width: $md;
				}
			}
		}
		&.dso_btn_icon_link {
			border-radius: $sm;
			border-width: $xxxs;
			.dso_btn_cont {
				padding: $xxs;
				.dso_btn_img {
					height: $lg;
					width: $lg;
				}
			}
		}
	}
	.dso_btn_cont {
		position: relative;
		left: 50%;
		padding: 1.25rem;
		transform: translateX(-50%);
		transition: inherit;
		.dso_btn_text {
			display: none;
		}
		.dso_btn_img {
			transition: 0.125s;
			position: relative;
			height: $xl;
			width: $xl;
		}
	}
	&.dso_btn_icon_link {
		border-color: $primary_100;
		color: $neutral_900;
		fill: $neutral_900;
		.dso_btn_cont {
			padding: $sm;
		}
	}
	&.dso_btn_icon_fill {
		box-shadow: $shadow_hard;
		border: 0;
		border-radius: $xs;
		@each $name in $color_name {
			$i: index($color_name, $name);
			&.dso_btn_color_#{$name} {
				background-color: nth($color, $i);
				color: nth($contrast, $i);
				fill: nth($contrast, $i);
			}
		}

		.dso_btn_cont {
			padding: var(--xs) var(--sm);
		}
	}
	&.dso_btn_icon_edit {
		border-radius: $xs;
		border: none;
		color: $primary_300;
		fill: $primary_300;
		background-color: $neutral_0;
		box-shadow: $shadow_hard;
		.dso_btn_cont {
			padding: $xs;
			.dso_btn_img {
				height: $xl;
				width: $xl;
			}
		}
	}
}

@media (hover: hover) {
	.dso_btn_outline:hover {
		border-color: transparent;
		color: white;
		fill: white;

		@each $name in $color_name {
			$i: index($color_name, $name);
			&.dso_btn_color_#{$name} {
				background-color: nth($hover, $i);
			}
		}
		.dso_btn_cont {
			bottom: 0;
		}
	}
	.dso_btn_selector:hover {
		background: $neutral_800;
		color: $neutral_contrast_800;
		fill: $neutral_contrast_800;
	}
	.dso_btn_icon {
		&:hover {
			border-color: $primary_300;
			color: $primary_300;
			fill: $primary_300;
		}
		&.dso_btn_icon_link:hover {
			border-color: $primary_300;
			background-color: $neutral_0;
		}
		&.dso_btn_icon_fill:hover {
			.dso_btn_cont {
				bottom: $xxs;
			}
		}
		&.dso_btn_icon_edit:hover {
			box-shadow: $shadow_hard_inv;
			background-color: $primary_300;
			color: white;
			fill: white;
		}
	}
}
