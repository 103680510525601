.hide_checkbox {
	pointer-events: none;
	opacity: 0;
}

.table_divider {
	width: 1px;
	background-color: var(--neutral_600);
}

.sortIcons{
	position: absolute;
	top: 50%;
	left: 115%;
	transform: translate(-50%, -50%);
	display:flex;
	flex-direction: column;
	gap: var(--xs);
	.up{
		transform: rotate(180deg) translateY(calc(-1 * var(--xs)));
		&.selected{
			transform: rotate(180deg);
		}
	}
	.down{
		transform:translateY(calc(-1 * var(--xs)));
		&.selected{
			transform: none
		}
	}
	
}
