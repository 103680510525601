@import './rem';
@import './variables';
@mixin dso($subname) {
	/**=====================
    0. Container CSS Start
  ==========================**/

	.dso_container#{$subname} {
		max-width: 1440px;
		padding-right: var(--xl);
		padding-left: var(--xl);
		margin: 0 auto;
	}

	/**=====================
    0. Container CSS End
  ==========================**/

	/**=====================
    01. Colors CSS Start
==========================**/

	$name: neutral_0, primary_25, primary_50, primary_100, primary_200,
		primary_300, primary_400, primary_500, primary_600, primary_gradient,
		secondary_100, secondary_400, secondary_500, secondary_600, secondary_700,
		secondary_800, neutral_100, neutral_200, neutral_300, neutral_400,
		neutral_500, neutral_600, neutral_700, neutral_800, neutral_900,
		neutral_1000, green_100, green_200, green_300, green_400, green_500, red_100,
		red_200, red_300, red_400, red_500, yellow_100, 'transparent';
	$color: $neutral_0, $primary_25, $primary_50, $primary_100, $primary_200,
		$primary_300, $primary_400, $primary_500, $primary_600, $primary_gradient,
		$secondary_100, $secondary_400, $secondary_500, $secondary_600,
		$secondary_700, $secondary_800, $neutral_100, $neutral_200, $neutral_300,
		$neutral_400, $neutral_500, $neutral_600, $neutral_700, $neutral_800,
		$neutral_900, $neutral_1000, $green_100, $green_200, $green_300, $green_400,
		$green_500, $red_100, $red_200, $red_300, $red_400, $red_500, $yellow_100,
		transparent;
	$contrast: $neutral_contrast_0, $primary_contrast_25, $primary_contrast_50,
		$primary_contrast_100, $primary_contrast_200, $primary_contrast_300,
		$primary_contrast_400, $primary_contrast_500, $primary_contrast_600,
		$primary_contrast_gradient, $secondary_contrast_100, $secondary_contrast_400,
		$secondary_contrast_500, $secondary_contrast_600, $secondary_contrast_700,
		$secondary_contrast_800, $neutral_contrast_100, $neutral_contrast_200,
		$neutral_contrast_300, $neutral_contrast_400, $neutral_contrast_500,
		$neutral_contrast_600, $neutral_contrast_700, $neutral_contrast_800,
		$neutral_contrast_900, $neutral_contrast_1000, $green_contrast_100,
		$green_contrast_200, $green_contrast_300, $green_contrast_400,
		$green_contrast_500, $red_contrast_100, $red_contrast_200, $red_contrast_300,
		$red_contrast_400, $red_contrast_500, $yellow_contrast_100, black;

	@each $var in $name {
		$i: index($name, $var);

		.bg_#{$var}#{$subname} {
			background-color: nth($color, $i) !important;
			fill: nth($contrast, $i) !important;
			color: nth($contrast, $i) !important;
		}
		.bg_#{$var}_nocontrast#{$subname} {
			background-color: nth($color, $i) !important;
		}

		.border_#{$var}#{$subname} {
			border-color: nth($color, $i) !important;
			stroke: nth($color, $i) !important;
		}
		.text_#{$var}#{$subname} {
			color: nth($color, $i) !important;
			fill: nth($color, $i) !important;
		}
	}

	/**=====================
    01. Colors CSS End
==========================**/

	/**=====================
    02. Text CSS Start
==========================**/
	.text_link {
		cursor: pointer;
		color: $secondary_500;
		text-decoration: underline;
	}

	$name: display, heading1, heading2, heading3, heading4, large, medium, small,
		xsmall, xxsmall;
	$size: 4.5rem, 3rem, $xxl, $xl, $lg, 1.25rem, $md, 0.875rem, $sm, 0.625rem;
	$line-height: 5rem, 3.5rem, 3rem, $xxl, $xl, $xl, $lg, 1.25rem, $md, $sm;
	$weight: 600, 400;

	@each $var in $name {
		$i: index($name, $var);
		@each $w in $weight {
			.text_#{$var}_#{$w}#{$subname} {
				font-size: nth($size, $i) !important;
				line-height: nth($line-height, $i) !important;
				font-weight: $w !important;
			}
		}
	}

	$i: none, overline, line-through, underline;

	@each $val in $i {
		.text_decoration_#{$val} {
			text-decoration-line: $val;
		}
	}
	$i: dashed, dotted, solid, wavy, double;
	@each $val in $i {
		.text_decoration_#{$val}#{$subname} {
			text-decoration-style: $val;
		}
	}
	$i: inherit, initial, unset;
	@each $val in $i {
		.text_decoration_#{$val}#{$subname} {
			text-decoration: $val;
		}
	}

	.text_center#{$subname} {
		text-align: center;
	}
	.text_right#{$subname} {
		text-align: right;
	}
	.text_left#{$subname} {
		text-align: left;
	}

	$i: capitalize, uppercase, lowercase, none;
	@each $val in $i {
		.text_#{$val}#{$subname} {
			text-transform: $val !important;
		}
	}

	/**=====================
    02. Text CSS End
==========================**/

	/**=====================
    03. Spacing and Sizing CSS Start
==========================**/

	$name: none, xxxs, xxs, xs, sm, md, lg, xl, xxl, xxxl, auto;
	$size: $none, $xxxs, $xxs, $xs, $sm, $md, $lg, $xl, $xxl, $xxxl, auto;

	@each $var in $name {
		$i: index($name, $var);
		.m_#{$var}#{$subname} {
			margin: nth($size, $i);
		}
		.m_t_#{$var}#{$subname} {
			margin-top: nth($size, $i) !important;
		}
		.m_b_#{$var}#{$subname} {
			margin-bottom: nth($size, $i) !important;
		}
		.m_l_#{$var}#{$subname} {
			margin-left: nth($size, $i) !important;
		}
		.m_r_#{$var}#{$subname} {
			margin-right: nth($size, $i) !important;
		}
		.m_x_#{$var}#{$subname} {
			margin-left: nth($size, $i) !important;
			margin-right: nth($size, $i) !important;
		}
		.m_y_#{$var}#{$subname} {
			margin-top: nth($size, $i) !important;
			margin-bottom: nth($size, $i) !important;
		}
		.p_#{$var}#{$subname} {
			padding: nth($size, $i);
		}
		.p_t_#{$var}#{$subname} {
			padding-top: nth($size, $i) !important;
		}
		.p_b_#{$var}#{$subname} {
			padding-bottom: nth($size, $i) !important;
		}
		.p_l_#{$var}#{$subname} {
			padding-left: nth($size, $i) !important;
		}
		.p_r_#{$var}#{$subname} {
			padding-right: nth($size, $i) !important;
		}
		.p_x_#{$var}#{$subname} {
			padding-left: nth($size, $i) !important;
			padding-right: nth($size, $i) !important;
		}
		.p_y_#{$var}#{$subname} {
			padding-top: nth($size, $i) !important;
			padding-bottom: nth($size, $i) !important;
		}
		.w_#{$var}#{$subname} {
			width: nth($size, $i);
		}
		.h_#{$var}#{$subname} {
			height: nth($size, $i);
		}
		.dim_#{$var}#{$subname} {
			height: nth($size, $i);
			width: nth($size, $i);
		}
	}

	$i: 5;
	@while $i<=100 {
		//Pixels
		.w_#{$i}_px#{$subname} {
			width: #{$i}px !important;
		}
		.h_#{$i}_px#{$subname} {
			height: #{$i}px !important;
		}
		.dim_#{$i}_px#{$subname} {
			width: #{$i}px !important;
			height: #{$i}px !important;
		}
		.w_#{$i}0_px#{$subname} {
			width: calc(#{$i}px * 10) !important;
		}
		.h_#{$i}0_px#{$subname} {
			height: calc(#{$i}px * 10) !important;
		}
		.dim_#{$i}0_px#{$subname} {
			width: calc(#{$i}px * 10) !important;
			height: calc(#{$i}px * 10) !important;
		}
		//Percentage
		.w_#{$i}_per#{$subname} {
			width: #{$i * 1%} !important;
		}
		.h_#{$i}_per#{$subname} {
			height: #{$i * 1%} !important;
		}
		.dim_#{$i}_per#{$subname} {
			width: #{$i * 1%} !important;
			height: #{$i * 1%} !important;
		}
		.m_#{$i}_per#{$subname} {
			margin: #{$i * 1%};
		}
		.m_t_#{$i}_per#{$subname} {
			margin-top: #{$i * 1%} !important;
		}
		.m_b_#{$i}_per#{$subname} {
			margin-bottom: #{$i * 1%} !important;
		}
		.m_l_#{$i}_per#{$subname} {
			margin-left: #{$i * 1%} !important;
		}
		.m_r_#{$i}_per#{$subname} {
			margin-right: #{$i * 1%} !important;
		}
		.m_x_#{$i}_per#{$subname} {
			margin-left: #{$i * 1%} !important;
			margin-right: #{$i * 1%} !important;
		}
		.m_y_#{$i}_per#{$subname} {
			margin-top: #{$i * 1%} !important;
			margin-bottom: #{$i * 1%} !important;
		}
		.p_#{$i}_per#{$subname} {
			padding: #{$i * 1%};
		}
		.p_t_#{$i}_per#{$subname} {
			padding-top: #{$i * 1%} !important;
		}
		.p_b_#{$i}_per#{$subname} {
			padding-bottom: #{$i * 1%} !important;
		}
		.p_l_#{$i}_per#{$subname} {
			padding-left: #{$i * 1%} !important;
		}
		.p_r_#{$i}_per#{$subname} {
			padding-right: #{$i * 1%} !important;
		}
		.p_x_#{$i}_per#{$subname} {
			padding-left: #{$i * 1%} !important;
			padding-right: #{$i * 1%} !important;
		}
		.p_y_#{$i}_per#{$subname} {
			padding-top: #{$i * 1%} !important;
			padding-bottom: #{$i * 1%} !important;
		}
		//Viewport
		.w_#{$i}_vp#{$subname} {
			width: #{$i * 1vw} !important;
		}
		.h_#{$i}_vp#{$subname} {
			height: #{$i * 1vh} !important;
		}
		.dim_#{$i}_vp#{$subname} {
			width: #{$i * 1vw} !important;
			height: #{$i * 1vh} !important;
		}
		$i: $i + 5;
	}

	.dim_fit#{$subname} {
		height: fit-content;
		width: fit-content;
	}
	.h_fit#{$subname} {
		height: fit-content;
	}
	.w_fit#{$subname} {
		width: fit-content;
	}

	/**=====================
    03. Spacing CSS End
==========================**/

	/**=====================
    04. Border CSS Start
==========================**/

	$name: none, xxxs, xxs, xs, sm, md, lg, xl, xxl, xxxl, circle;
	$size: $none, $xxxs, $xxs, $xs, $sm, $md, $lg, $xl, $xxl, $xxxl, 50%;

	@each $var in $name {
		$i: index($name, $var);
		.br_#{$var}#{$subname} {
			border-radius: nth($size, $i);
		}
		.br_t_#{$var}#{$subname} {
			border-top-left-radius: nth($size, $i);
			border-top-right-radius: nth($size, $i);
		}
		.br_tl_#{$var}#{$subname} {
			border-top-left-radius: nth($size, $i);
		}
		.br_tr_#{$var}#{$subname} {
			border-top-left-radius: nth($size, $i);
		}
		.br_b_#{$var}#{$subname} {
			border-bottom-left-radius: nth($size, $i);
			border-bottom-right-radius: nth($size, $i);
		}
		.br_bl_#{$var}#{$subname} {
			border-bottom-left-radius: nth($size, $i);
		}
		.br_br_#{$var}#{$subname} {
			border-bottom-right-radius: nth($size, $i);
		}
		.br_l_#{$var}#{$subname} {
			border-top-left-radius: nth($size, $i);
			border-bottom-left-radius: nth($size, $i);
		}
		.br_r_#{$var}#{$subname} {
			border-top-right-radius: nth($size, $i);
			border-bottom-right-radius: nth($size, $i);
		}
		.br_cross_r_#{$var}#{$subname} {
			border-top-right-radius: nth($size, $i);
			border-bottom-left-radius: nth($size, $i);
		}
		.br_cross_l_#{$var}#{$subname} {
			border-top-left-radius: nth($size, $i);
			border-bottom-right-radius: nth($size, $i);
		}

		.border_#{$var}#{$subname} {
			border-width: nth($size, $i);
		}
		.border_t_#{$var}#{$subname} {
			border-top-width: nth($size, $i);
		}
		.border_l_#{$var}#{$subname} {
			border-left-width: nth($size, $i);
		}
		.border_b_#{$var}#{$subname} {
			border-bottom-width: nth($size, $i);
		}
		.border_r_#{$var}#{$subname} {
			border-right-width: nth($size, $i);
		}
		.border_x_#{$var}#{$subname} {
			border-left-width: nth($size, $i);
			border-right-width: nth($size, $i);
		}
		.border_y_#{$var}#{$subname} {
			border-bottom-width: nth($size, $i);
			border-top-width: nth($size, $i);
		}
	}
	$i: none, hidden, dotted, dashed, solid, double, groove, ridge, inset, outset;
	@each $val in $i {
		.border_#{$val}#{$subname} {
			border-style: $val;
		}
		.border_t_#{$val}#{$subname} {
			border-top-style: $val;
		}
		.border_l_#{$val}#{$subname} {
			border-left-style: $val;
		}
		.border_b_#{$val}#{$subname} {
			border-bottom-style: $val;
		}
		.border_r_#{$val}#{$subname} {
			border-right-style: $val;
		}
	}

	$i: 0;
	@while $i<=10 {
		.border_#{$i}#{$subname} {
			border-width: #{$i}px;
		}
		.border_t_#{$i}#{$subname} {
			border-top-width: #{$i}px;
		}
		.border_l_#{$i}#{$subname} {
			border-left-width: #{$i}px;
		}
		.border_b_#{$i}#{$subname} {
			border-bottom-width: #{$i}px;
		}
		.border_r_#{$i}#{$subname} {
			border-right-width: #{$i}px;
		}

		.border_x_#{$i}#{$subname} {
			border-left-width: #{$i}px;
			border-right-width: #{$i}px;
		}
		.border_y_#{$i}#{$subname} {
			border-bottom-width: #{$i}px;
			border-top-width: #{$i}px;
		}
		$i: $i + 1;
	}

	/**=====================
    04. Border CSS End
==========================**/

	/**=====================
    05. Shadow CSS Start
==========================**/

	.shadow_none#{$subname} {
		box-shadow: none;
	}

	.shadow_soft#{$subname} {
		box-shadow: $shadow_soft;
	}

	.shadow_soft_inv#{$subname} {
		box-shadow: $shadow_soft_inv;
	}

	.shadow_medium#{$subname} {
		box-shadow: $shadow_medium;
	}

	.shadow_medium_inv#{$subname} {
		box-shadow: $shadow_medium_inv;
	}

	.shadow_hard#{$subname} {
		box-shadow: $shadow_hard;
	}

	.shadow_hard_inv#{$subname} {
		box-shadow: $shadow_hard_inv;
	}

	/**=====================
    05. Shadow CSS End
==========================**/

	/**=====================
    08. Cards CSS Start
==========================**/
	.dso_card#{$subname} {
		border-radius: $md;
		box-shadow: $shadow_hard;
	}
	.dso_card_small#{$subname} {
		border-radius: $xs;
		box-shadow: $shadow_hard;
	}
	.dso_card_icon#{$subname} {
		border-radius: $sm;
		padding: $xs;
		& > * {
			height: $xxl;
			width: $xxl;
		}
	}
	.dso_card_img#{$subname} {
		border-radius: $lg;
		border: $xxs solid $neutral_500;
	}
	.dso_card_img_small#{$subname} {
		border-radius: $sm;
		border: $xxxs solid $neutral_500;
	}
	.dso_chip#{$subname} {
		border-radius: $xs;
		line-height: $lg;
		font-size: $md;
		font-weight: 800;
		padding: $xs $md;
	}
	.dso_chip_small#{$subname} {
		border-radius: $xs;
		line-height: $md;
		font-size: $sm;
		font-weight: 800;
		padding: $xxs $md;
	}
	.dso_chip_large#{$subname} {
		border-radius: $xs;
		line-height: $xl;
		font-size: 1.25rem;
		font-weight: 800;
		padding: $xs 2.25rem;
	}
	.dso_chip_rounded#{$subname} {
		border-radius: $lg;
	}
	/**=====================
    08. Cards CSS End
==========================**/

	/**=====================
    08. Utils CSS End
==========================**/

	/*====== Images css starts ======*/

	$i: fill, contain, cover, none, scale-down;
	@each $val in $i {
		.img_#{$val}#{$subname} {
			height: 100%;
			width: 100%;
			object-fit: $val;
		}
	}

	/*====== Images css ends ======*/

	/*====== Placement css starts ======*/

	.center#{$subname} {
		position: relative;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.center_y#{$subname} {
		position: relative;
		top: 50%;
		transform: translate(0, -50%);
	}

	.center_x#{$subname} {
		position: relative;
		left: 50%;
		transform: translate(-50%, 0);
	}

	/*====== Placement css ends ======*/

	/*====== Position css starts ======*/

	$i: static, absolute, fixed, relative, initial, inherit;
	@each $val in $i {
		.pos_#{$val}#{$subname} {
			position: $val;
		}
	}
	/*====== Position css ends ======*/

	/*====== Display css stars ======*/

	$names: block, inline, inline_block, flex, inline_flex, grid, inline_grid,
		flow_root, none, contents;
	$props: block, inline, inline-block, flex, inline-flex, grid, inline-grid,
		flow-root, none, contents;

	@each $val in $names {
		$i: index($names, $val);
		.display_#{$val}#{$subname} {
			display: nth($props, $i) !important;
		}
	}

	/*====== Display css ends ======*/

	/*====== Overflow css starts ======*/
	$i: hidden, visible, scroll, auto;
	@each $val in $i {
		.overflow_#{$val}#{$subname} {
			overflow: $val !important;
		}
		.overflow_x_#{$val}#{$subname} {
			overflow-x: $val !important;
		}
		.overflow_y_#{$val}#{$subname} {
			overflow-y: $val !important;
		}
	}

	.overflow_ellipsis#{$subname} {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	/*====== Overflow css ends ======*/

	/*====== Cursor css starts ======*/
	$names: auto, default, none, context_menu, help, pointer, progress, wait, cell,
		crosshair, text, vertical_text, alias, copy, move, no_drop, not_allowed,
		all_scroll, col_resize, e_resize, ew_resize, n_resize, ne_resize,
		nesw_resize, ns_resize, nw_resize, nwse_resize, row_resize, s_resize,
		se_resize, sw_resize, w_resize;
	$props: auto, default, none, context-menu, help, pointer, progress, wait, cell,
		crosshair, text, vertical-text, alias, copy, move, no-drop, not-allowed,
		all-scroll, col-resize, e-resize, ew-resize, n-resize, ne-resize,
		nesw-resize, ns-resize, nw-resize, nwse-resize, row-resize, s-resize,
		se-resize, sw-resize, w-resize;

	@each $val in $names {
		$i: index($names, $val);
		.cursor_#{$val}#{$subname} {
			cursor: nth($props, $i);
		}
	}
	/*====== Cursor css ends ======*/

	/*====== z-index css starts ======*/
	$i: 0;
	@while $i<=100 {
		.z_index_#{$i}#{$subname} {
			z-index: #{$i};
		}
		$i: $i + 1;
	}
	.z_index_negative#{$subname} {
		z-index: -1;
	}
	/*====== z-index css ends ======*/

	.flex_content_col#{$subname} {
		display: flex;
		flex-direction: column;
		height: 100%;
		.flex_header#{$subname} {
			flex-basis: auto;
			flex-grow: 0;
		}
		.flex_body#{$subname} {
			flex-basis: 0;
			flex-shrink: 3;
			overflow-y: scroll;
			margin-top: 0;
			flex-grow: 2;
		}
		.flex_footer#{$subname} {
			margin-top: var(--md);
			flex-basis: auto;
			flex-grow: 0;
		}
	}
	.flex_content_row#{$subname} {
		display: flex;
		flex-direction: row;
		width: 100%;
		.flex_header#{$subname} {
			flex-basis: auto;
			flex-grow: 0;
		}
		.flex_body#{$subname} {
			flex-basis: 0;
			flex-shrink: 3;
			overflow-x: scroll;
			margin-left: 0;
			flex-grow: 2;
		}
		.flex_footer#{$subname} {
			margin-left: var(--md);
			flex-basis: auto;
			flex-grow: 0;
		}
	}

	.flex_center#{$subname} {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.flex_center_col#{$subname} {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.flex_row#{$subname} {
		flex-direction: row !important;
	}
	.flex_col#{$subname} {
		flex-direction: column !important;
	}
	.flex_row_reverse#{$subname} {
		flex-direction: row-reverse !important;
	}
	.flex_col_reverse#{$subname} {
		flex-direction: column-reverse !important;
	}
	$names: start, end, center, between, around, evenly;
	$values: start, end, center, space-between, space-around, space-evenly;
	@each $val in $names {
		$i: index($names, $val);
		.flex_justify_#{$val}#{$subname} {
			justify-content: nth($values, $i);
		}
	}
	$names: start, end, center, baseline, stretch;
	$values: flex-start, flex-end, center, baseline, stretch;
	@each $val in $names {
		$i: index($names, $val);
		.flex_align_#{$val}#{$subname} {
			align-items: nth($values, $i);
		}
		.flex_align_self_#{$val}#{$subname} {
			align-self: nth($values, $i);
		}
	}

	.flex_fill#{$subname} {
		flex: 1 1 auto;
	}

	$i: 0;
	@while $i<=10 {
		.flex_#{$i}#{$subname} {
			flex: $i;
		}
		.flex_grow_#{$i}#{$subname} {
			flex-grow: $i;
		}

		.flex_grow_#{$i}#{$subname} {
			flex-grow: $i;
		}

		.flex_shrink_#{$i}#{$subname} {
			flex-shrink: $i;
		}

		.flex_shrink_#{$i}#{$subname} {
			flex-shrink: $i;
		}
		$i: $i + 1;
	}

	.flex_basis_0#{$subname} {
		flex-basis: 0;
	}

	$names: nowrap, wrap, wrap_reverse;
	$values: nowrap, wrap, wrap-reverse;
	@each $val in $names {
		$i: index($names, $val);
		.flex_#{$val}#{$subname} {
			flex-wrap: nth($values, $i);
		}
	}
	$names: first, 0, 1, 2, 3, 4, 5, last;
	$values: -1, 0, 1, 2, 3, 4, 5, 6;
	@each $val in $names {
		$i: index($names, $val);
		.flex_order_#{$val}#{$subname} {
			order: nth($values, $i);
		}
	}

	$names: start, end, center, around, between, stretch;
	$values: flex-start, flex-end, center, space-around, space-between, stretch;
	@each $val in $names {
		$i: index($names, $val);
		.flex_align_content_#{$val}#{$subname} {
			align-content: nth($values, $i);
		}
	}

	$name: xxxs, xxs, xs, sm, md, lg, xl, xxl, xxxl;
	$size: $xxxs, $xxs, $xs, $sm, $md, $lg, $xl, $xxl, $xxxl;

	@each $var in $name {
		$i: index($name, $var);
		.flex_gap_#{$var}#{$subname} {
			gap: nth($size, $i);
		}
	}
}

@include dso('');

@media screen and (min-width: $dso_bp_desktop) {
	@include dso('_desktop');
}
@media screen and (max-width: $dso_bp_desktop) {
	@include dso('_mobile');
}
