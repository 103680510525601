
.fcards {
  border-left: 1px solid #424242;
}


@media screen and (max-width: 600px) {
  .fcards {
    border-left: 0;
    border-right: 0;
    width: 100%;
    border-top: 1px solid #424242;
  }
}

.footer_brands {
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
  & .footer_brand {
    margin-bottom: 10px;
    margin-right: 10px;
  }
}