.allies-item-container {
  display: flex;
  min-width: 185px;
  height: 63px;
  justify-content: center;
  align-items: center;

  & svg {
    width: 59.87px;
    height: 59.87px;
  }

  &.has-link:hover {
    cursor: pointer;
  }

  &.full-logo svg {
    width: 136px;
    object-fit: cover;
  }
}