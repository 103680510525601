@use "~design_system/src/styles/_variables.scss" as *;

.form-application {
  display: grid;
  grid-template-columns: minmax(300px, 900px) minmax(300px, 900px);

  @media (max-width: $dso_bp_desktop) {
    grid-template-columns: 100%;
  }

  grid-template-rows: 1fr;
  place-content: center;

  & .card-container {
    max-width: 668px;
    width: 100%;
    background: #f7f7f7;
  }
}
