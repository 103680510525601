@use '../../styles/variables' as *;

.ozon-table-body {
	& tr {
		box-shadow: 0px 4px 8px rgba(34, 38, 42, 0.05);
		height: 56px;
	}

	& tr span.dso_input_checkbox {
		background-color: $neutral_0;
	}
}
