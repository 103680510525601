/** Colors **/
/*** Primary ***/
$primary_25: #fcf2e7;
$primary_25_rgb: 252, 242, 231;
$primary_contrast_25: #000000;
$primary_contrast_25_rgb: 0, 0, 0;

$primary_50: #fde9d3;
$primary_50_rgb: 253, 233, 211;
$primary_contrast_50: #000000;
$primary_contrast_50_rgb: 0, 0, 0;

$primary_100: #ffd8ab;
$primary_100_rgb: 255, 216, 171;
$primary_contrast_100: #000000;
$primary_contrast_100_rgb: 0, 0, 0;

$primary_200: #ffad4f;
$primary_200_rgb: 255, 173, 79;
$primary_contrast_200: #000000;
$primary_contrast_200_rgb: 0, 0, 0;

$primary_300: #fe8a02;
$primary_300_rgb: 254, 138, 2;
$primary_contrast_300: #ffffff;
$primary_contrast_300_rgb: 255, 255, 255;

$primary_400: #cc6e02;
$primary_400_rgb: 204, 110, 2;
$primary_contrast_400: #ffffff;
$primary_contrast_400_rgb: 255, 255, 255;

$primary_500: #805628;
$primary_500_rgb: 128, 86, 40;
$primary_contrast_500: #ffffff;
$primary_contrast_500_rgb: 255, 255, 255;

$primary_600: #804501;
$primary_600_rgb: 128, 69, 1;
$primary_contrast_600: #ffffff;
$primary_contrast_600_rgb: 255, 255, 255;

$primary_gradient: linear-gradient(90deg, #f28e18 0%, #cc6e02 100%);
$primary_contrast_gradient: #ffffff;
$primary_contrast_gradient_rgb: 0, 0, 0;

/*** Secondary ***/

$secondary_100: #c4daff;
$secondary_100_rgb: 196, 218, 255;
$secondary_contrast_100: #000000;
$secondary_contrast_100_rgb: 0, 0, 0;

$secondary_400: #6f9dec;
$secondary_400_rgb: 111, 157, 236;
$secondary_contrast_400: #000000;
$secondary_contrast_400_rgb: 0, 0, 0;

$secondary_500: #286ee8;
$secondary_500_rgb: 40, 110, 232;
$secondary_contrast_500: #000000;
$secondary_contrast_500_rgb: 0, 0, 0;

$secondary_600: #1f56b5;
$secondary_600_rgb: 31, 86, 181;
$secondary_contrast_600: #ffffff;
$secondary_contrast_600_rgb: 255, 255, 255;

$secondary_700: #123269;
$secondary_700_rgb: 18, 50, 105;
$secondary_contrast_700: #ffffff;
$secondary_contrast_700_rgb: 255, 255, 255;

$secondary_800: #314569;
$secondary_800_rgb: 49, 69, 105;
$secondary_contrast_800: #ffffff;
$secondary_contrast_800_rgb: 255, 255, 255;

/*** Gray ***/

$neutral_0: #ffffff;
$neutral_0_rgb: 255, 255, 255;
$neutral_contrast_0: #000000;
$neutral_contrast_0_rgb: 0, 0, 0;

$neutral_100: #fbfbfb;
$neutral_100_rgb: 251, 251, 251;
$neutral_contrast_100: #000000;
$neutral_contrast_100_rgb: 0, 0, 0;

$neutral_200: #f4f5f6;
$neutral_200_rgb: 244, 245, 246;
$neutral_contrast_200: #000000;
$neutral_contrast_200_rgb: 0, 0, 0;

$neutral_300: #ecedef;
$neutral_300_rgb: 236, 237, 239;
$neutral_contrast_300: #000000;
$neutral_contrast_300_rgb: 0, 0, 0;

$neutral_400: #e0e3e5;
$neutral_400_rgb: 224, 227, 229;
$neutral_contrast_400: #000000;
$neutral_contrast_400_rgb: 0, 0, 0;

$neutral_500: #c7ccd1;
$neutral_500_rgb: 199, 204, 209;
$neutral_contrast_500: #000000;
$neutral_contrast_500_rgb: 0, 0, 0;

$neutral_600: #8f99a3;
$neutral_600_rgb: 143, 153, 163;
$neutral_contrast_600: #000000;
$neutral_contrast_600_rgb: 0, 0, 0;

$neutral_700: #67737e;
$neutral_700_rgb: 103, 115, 126;
$neutral_contrast_700: #ffffff;
$neutral_contrast_700_rgb: 255, 255, 255;

$neutral_800: #454d54;
$neutral_800_rgb: 69, 77, 81;
$neutral_contrast_800: #ffffff;
$neutral_contrast_800_rgb: 255, 255, 255;

$neutral_900: #232323;
$neutral_900_rgb: 35, 35, 35;
$neutral_contrast_900: #ffffff;
$neutral_contrast_900_rgb: 255, 255, 255;

$neutral_1000: #000000;
$neutral_1000_rgb: 0, 0, 0;
$neutral_contrast_1000: #ffffff;
$neutral_contrast_1000_rgb: 255, 255, 255;

/*** Green ***/

$green_100: #ebf9f1;
$green_100_rgb: 235, 249, 241;
$green_contrast_100: #000000;
$green_contrast_100_rgb: 0, 0, 0;

$green_200: #d7f4e3;
$green_200_rgb: 215, 244, 227;
$green_contrast_200: #000000;
$green_contrast_200_rgb: 0, 0, 0;

$green_300: #0ac257;
$green_300_rgb: 10, 194, 87;
$green_contrast_300: #000000;
$green_contrast_300_rgb: 0, 0, 0;

$green_400: #00662b;
$green_400_rgb: 0, 102, 43;
$green_contrast_400: #ffffff;
$green_contrast_400_rgb: 255, 255, 255;

$green_500: #003315;
$green_500_rgb: 0, 51, 21;
$green_contrast_500: #ffffff;
$green_contrast_500_rgb: 255, 255, 255;

/*** Red ***/

$red_100: #fff0f2;
$red_100_rgb: 255, 240, 242;
$red_contrast_100: #000000;
$red_contrast_100_rgb: 0, 0, 0;

$red_200: #ffe0e6;
$red_200_rgb: 255, 224, 230;
$red_contrast_200: #000000;
$red_contrast_200_rgb: 0, 0, 0;

$red_300: #df2040;
$red_300_rgb: 223, 32, 64;
$red_contrast_300: #ffffff;
$red_contrast_300_rgb: 255, 255, 255;

$red_400: #971128;
$red_400_rgb: 151, 17, 40;
$red_contrast_400: #ffffff;
$red_contrast_400_rgb: 255, 255, 255;

$red_500: #37060e;
$red_500_rgb: 55, 6, 14;
$red_contrast_500: #ffffff;
$red_contrast_500_rgb: 255, 255, 255;

/*** Yellow ***/

$yellow_100: #ecc026;
$yellow_100_rgb: 236, 192, 38;
$yellow_contrast_100: #000000;
$yellow_contrast_100_rgb: 0, 0, 0;

/*** Space ***/

$none: 0;
$xxxs: 0.125rem;
$xxs: 0.25rem;
$xs: 0.5rem;
$sm: 0.75rem;
$md: 1rem;
$lg: 1.5rem;
$xl: 2rem;
$xxl: 2.5rem;
$xxxl: 3rem;

/*** Shadows ***/

$shadow_soft: 2px 4px 8px rgba(34, 38, 42, 0.05);
$shadow_soft_inv: 2px -4px 8px rgba(34, 38, 42, 0.05);
$shadow_medium: 2px 4px 8px rgba(34, 38, 42, 0.03),
	4px 8px 16px rgba(34, 38, 42, 0.05);
$shadow_medium_inv: 2px -4px 8px rgba(34, 38, 42, 0.03),
	4px -8px 16px rgba(34, 38, 42, 0.05);
$shadow_hard: 2px 4px 8px rgba(34, 38, 42, 0.05),
	4px 8px 24px rgba(34, 38, 42, 0.1);
$shadow_hard_inv: 2px -4px 8px rgba(34, 38, 42, 0.05),
	4px -8px 24px rgba(34, 38, 42, 0.1);

$dso_bp_desktop: 600px;

:root {
	--primary_50: #fcf2e7;
	--primary_50_rgb: 252, 242, 231;
	--primary_contrast_50: #000000;
	--primary_contrast_50_rgb: 0, 0, 0;

	--primary_50: #fde9d3;
	--primary_50_rgb: 253, 233, 211;
	--primary_contrast_50: #000000;
	--primary_contrast_50_rgb: 0, 0, 0;

	--primary_100: #ffd8ab;
	--primary_100_rgb: 255, 216, 171;
	--primary_contrast_100: #000000;
	--primary_contrast_100_rgb: 0, 0, 0;

	--primary_200: #ffad4f;
	--primary_200_rgb: 255, 173, 79;
	--primary_contrast_200: #000000;
	--primary_contrast_200_rgb: 0, 0, 0;

	--primary_300: #fe8a02;
	--primary_300_rgb: 254, 138, 2;
	--primary_contrast_300: #ffffff;
	--primary_contrast_300_rgb: 255, 255, 255;

	--primary_400: #cc6e02;
	--primary_400_rgb: 204, 110, 2;
	--primary_contrast_400: #ffffff;
	--primary_contrast_400_rgb: 255, 255, 255;

	--primary_500: #805628;
	--primary_500_rgb: 128, 86, 40;
	--primary_contrast_500: #ffffff;
	--primary_contrast_500_rgb: 255, 255, 255;

	--primary_600: #804501;
	--primary_600_rgb: 128, 69, 1;
	--primary_contrast_600: #ffffff;
	--primary_contrast_600_rgb: 255, 255, 255;

	--primary_gradient: linear-gradient(90deg, #f28e18 0%, #cc6e02 100%);
	--primary_contrast_gradient: #000000;
	--primary_contrast_gradient_rgb: 0, 0, 0;

	/*** Secondary ***/

	--secondary_100: #c4daff;
	--secondary_100_rgb: 196, 218, 255;
	--secondary_contrast_100: #000000;
	--secondary_contrast_100_rgb: 0, 0, 0;

	--secondary_400: #6f9dec;
	--secondary_400_rgb: 111, 157, 236;
	--secondary_contrast_400: #000000;
	--secondary_contrast_400_rgb: 0, 0, 0;

	--secondary_500: #286ee8;
	--secondary_500_rgb: 40, 110, 232;
	--secondary_contrast_500: #000000;
	--secondary_contrast_500_rgb: 0, 0, 0;

	--secondary_600: #1f56b5;
	--secondary_600_rgb: 31, 86, 181;
	--secondary_contrast_600: #000000;
	--secondary_contrast_600_rgb: 0, 0, 0;

	--secondary_700: #123269;
	--secondary_700_rgb: 18, 50, 105;
	--secondary_contrast_700: #ffffff;
	--secondary_contrast_700_rgb: 255, 255, 255;

	--secondary_800: #314569;
	--secondary_800_rgb: 49, 69, 105;
	--secondary_contrast_800: #ffffff;
	--secondary_contrast_800_rgb: 255, 255, 255;

	/*** Gray ***/

	--neutral_0: #ffffff;
	--neutral_0_rgb: 255, 255, 255;
	--neutral_contrast_0: #000000;
	--neutral_contrast_0_rgb: 0, 0, 0;

	--neutral_100: #fbfbfb;
	--neutral_100_rgb: 251, 251, 251;
	--neutral_contrast_100: #000000;
	--neutral_contrast_100_rgb: 0, 0, 0;

	--neutral_200: #f4f5f6;
	--neutral_200_rgb: 244, 245, 246;
	--neutral_contrast_200: #000000;
	--neutral_contrast_200_rgb: 0, 0, 0;

	--neutral_300: #ecedef;
	--neutral_300_rgb: 236, 237, 239;
	--neutral_contrast_300: #000000;
	--neutral_contrast_300_rgb: 0, 0, 0;

	--neutral_400: #e0e3e5;
	--neutral_400_rgb: 224, 227, 229;
	--neutral_contrast_400: #000000;
	--neutral_contrast_400_rgb: 0, 0, 0;

	--neutral_500: #c7ccd1;
	--neutral_500_rgb: 199, 204, 209;
	--neutral_contrast_500: #000000;
	--neutral_contrast_500_rgb: 0, 0, 0;

	--neutral_600: #8f99a3;
	--neutral_600_rgb: 143, 153, 163;
	--neutral_contrast_600: #000000;
	--neutral_contrast_600_rgb: 0, 0, 0;

	--neutral_700: #67737e;
	--neutral_700_rgb: 103, 115, 126;
	--neutral_contrast_700: #ffffff;
	--neutral_contrast_700_rgb: 255, 255, 255;

	--neutral_800: #454d54;
	--neutral_800_rgb: 69, 77, 81;
	--neutral_contrast_800: #ffffff;
	--neutral_contrast_800_rgb: 255, 255, 255;

	--neutral_900: #232323;
	--neutral_900_rgb: 35, 35, 35;
	--neutral_contrast_900: #ffffff;
	--neutral_contrast_900_rgb: 255, 255, 255;

	--neutral_1000: #000000;
	--neutral_1000_rgb: 0, 0, 0;
	--neutral_contrast_1000: #ffffff;
	--neutral_contrast_1000_rgb: 255, 255, 255;

	/*** Green ***/

	--green_100: #ebf9f1;
	--green_100_rgb: 235, 249, 241;
	--green_contrast_100: #000000;
	--green_contrast_100_rgb: 0, 0, 0;

	--green_200: #d7f4e3;
	--green_200_rgb: 215, 244, 227;
	--green_contrast_200: #000000;
	--green_contrast_200_rgb: 0, 0, 0;

	--green_300: #0ac257;
	--green_300_rgb: 10, 194, 87;
	--green_contrast_300: #000000;
	--green_contrast_300_rgb: 0, 0, 0;

	--green_400: #00662b;
	--green_400_rgb: 0, 102, 43;
	--green_contrast_400: #ffffff;
	--green_contrast_400_rgb: 255, 255, 255;

	--green_500: #003315;
	--green_500_rgb: 0, 51, 21;
	--green_contrast_500: #ffffff;
	--green_contrast_500_rgb: 255, 255, 255;

	/*** Red ***/

	--red_100: #fff0f2;
	--red_100_rgb: 255, 240, 242;
	--red_contrast_100: #000000;
	--red_contrast_100_rgb: 0, 0, 0;

	--red_200: #ffe0e6;
	--red_200_rgb: 255, 224, 230;
	--red_contrast_200: #000000;
	--red_contrast_200_rgb: 0, 0, 0;

	--red_300: #df2040;
	--red_300_rgb: 223, 32, 64;
	--red_contrast_300: #ffffff;
	--red_contrast_300_rgb: 255, 255, 255;

	--red_400: #971128;
	--red_400_rgb: 151, 17, 40;
	--red_contrast_400: #ffffff;
	--red_contrast_400_rgb: 255, 255, 255;

	--red_500: #37060e;
	--red_500_rgb: 55, 6, 14;
	--red_contrast_500: #ffffff;
	--red_contrast_500_rgb: 255, 255, 255;

	--yellow_100: #ecc026;
	--yellow_100_rgb: 236, 192, 38;
	--yellow_contrast_100: #000000;
	--yellow_contrast_100_rgb: 0, 0, 0;

	--none: 0;
	--xxxs: 0.125rem;
	--xxs: 0.25rem;
	--xs: 0.5rem;
	--sm: 0.75rem;
	--md: 1rem;
	--lg: 1.5rem;
	--xl: 2rem;
	--xxl: 2.5rem;
	--xxxl: 3rem;

	--dso_bp_desktop: 600px;
}
