@use '../../../styles/variables' as *;

.dso_select {
	background-color: $neutral_0;
	box-shadow: $shadow_soft_inv;
	width: 100%;
	border: 1px solid $neutral_500;
	border-radius: $xxs;
	outline: none;
	padding: 0.625rem $md;
	padding-right: $xxxl;
	position: relative;
	display: flex;
	gap: $xs;
	flex-wrap: wrap;
	position: relative;
	min-height: 2.64rem;
	cursor: text;
}
.dso_select_cont.active {
	.dso_select {
		border-color: $primary_300 !important;
	}
}
.dso_select_cont.error {
	.dso_select,
	.dso_select_placeholder {
		border-color: $red_300 !important;
		color: $red_300 !important;
	}
}
.dso_select_cont.disabled {
	pointer-events: none;
	&,
	.dso_select,
	.selected_option,
	.dso_select_placeholder {
		background-color: $neutral_200 !important;
		color: $neutral_600 !important;
		fill: $neutral_600 !important;
	}
	.dso_option_tag,
	.dso_option_tag_text,
	.dso_option_tag_close {
		background-color: $neutral_300 !important;
		color: $neutral_600 !important;
		fill: $neutral_600 !important;
	}
}

.dso_select_filter {
	outline: none;
	border: none;
	line-height: 1.25rem;
	font-size: 0.875rem;
	font-weight: 800;
	color: $neutral_700;
	background: transparent;
}

.dso_select_arrow {
	position: absolute;
	right: $sm;
	top: 50%;
	transform: translateY(-50%);
}

.dso_select_list_cont {
	list-style: none;
	margin: 0;
	padding: 0;
	.dso_select_list_item {
		position: relative;
		&:hover {
			background-color: rgba($color: $primary_100, $alpha: 0.25);
			color: $primary_300;
			.dso_select_checkbox {
				border-color: $primary_300;
			}
			.dso_select_group_arrow {
				fill: $primary_300;
			}
		}
		&.selected {
			background-color: rgba($color: $primary_100, $alpha: 1);
			color: $primary_300;
		}
		.dso_select_list_label {
			display: block;
		}
		input {
			position: absolute;
			opacity: 0;
		}
	}
}

.dso_option_tag {
	background-color: rgba($color: $primary_100, $alpha: 0.25);
	padding: $xxs $xs;
	padding-right: 1.75rem;
	position: relative;
	.dso_option_tag_close {
		position: absolute;
		right: $xs;
		top: 50%;
		transform: translateY(-50%);
	}
}

.dso_select_checkbox {
	border: 1px solid $neutral_700;
	background-color: $neutral_0;
	display: flex;
	&.selected {
		border-color: $primary_300;
		background-color: $primary_300;
		.dso_input_checkbox_icon {
			opacity: 1;
			fill: $neutral_0;
		}
	}
	&.part_selected {
		border-color: $primary_300;
		.dso_input_checkbox_icon {
			opacity: 1;
		}
	}
	.dso_input_checkbox_icon {
		height: 0.625rem;
		width: 0.625rem;
		opacity: 0;
		fill: $primary_300;
	}
}

.dso_select_group_title {
	background-color: $neutral_300;
	.dso_select_group_arrow {
		fill: $neutral_600;
	}
}

.dso_select_filter {
	position: absolute;
	left: 0;
}

.dso_select_dropdown {
	max-height: 12.5rem;
	overflow-y: auto;
	min-width: 100%;
}
