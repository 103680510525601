.progress-bar {
	height: var(--lg);
	border-radius: var(--md);
	.fill {
		height: 100%;
		border-radius: var(--md);
		&.withValue {
			min-width: 0.9375rem;
		}
	}
}
