@use '../../../styles/variables' as *;

.stepper-count-item {
	width: 16px;
	height: 16px;

	@media (max-width: $dso_bp_desktop) {
		width: 24px;
		height: 24px;
	}

	background-color: $neutral_700;
	border-radius: 50%;
	color: $neutral_0;
	display: flex;
	justify-content: center;
	align-items: center;

	&.completed {
		background-color: $primary_300;
		width: 24px;
		height: 24px;

		&:hover {
			cursor: pointer;
		}
	}
}
