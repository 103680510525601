.workInDigitalPlatforms, .checksIncomes {
    input[type='radio']:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: white;
        // background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #d1d3d1;
        // border: 2px solid white;
    }

    input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #ffa500;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid #ffa500;
    }
}


.dso_input_cont.palenca_input {
	.dso_input {
		&:focus {
			border-color: #286ee8;
		}
	}

    .MuiSelect-standard {
        &:hover {
            border: 1px solid #286ee8 !important;
		}
    }
}

.input-code {
    width: 40px;
    height: 40px;
    font-size: 16px;
    text-align: center;
    border: 1px solid #8f99a3;
    color: #67737e;
    border-radius: 4px;
    &:focus-visible {
        border: none;
        outline: 1px solid #fe8a02;
    }
}

.dependantsCount {
    .dependantsCount-card {
        border: none;
        outline: none;
        position: relative;
        background-color: white;
        box-shadow: 2px 4px 8px rgba(34, 38, 42, 0.05), 4px 8px 24px rgba(34, 38, 42, 0.1);
        flex-grow: 1;
        text-align: center;
        padding: 10px 5px;
        font-size: 16px;
        border-radius: 8px;
        max-width: 50px;

        &:hover {
            background-color: #ffad4f;
            cursor: pointer;
        }

        &.dependantsCount-card--active {
            color: #f4f5f6;
            background-color: #fe8a02;
            &:hover {
                background-color: #fe8a02;
            }
        }
    }
}

.checksIncomes {
    .checksIncomes-card {
        flex-grow: 1;
        text-align: center;

        .checksIncomes-icon {
            width: 50px;
            height: 30px;
            stroke-width: 0;
        }
    }
}