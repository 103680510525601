.container_modal .dso_modal {
	position: absolute;
	max-height: 80vh;
	height: fit-content;
	background-color: var(--neutral_300);
	border-radius: var(--sm);
	.dso_modal_icon {
		border-radius: var(--sm);
		position: absolute;
		background-color: var(--neutral_0);
		padding: var(--md);
		top: calc(-1 * var(--xxl));
		left: var(--xl);
		& > * {
			height: var(--xxl);
			width: var(--xxl);
		}
	}
	& > .flex_body {
		padding: 0 var(--xxl);
		flex-basis: auto !important;
	}
	& > .flex_footer {
		background-color: var(--neutral_0);
		padding: var(--lg);
		border-radius: 0 0 var(--sm) var(--sm);
		overflow: hidden;
		display: flex;
	}
}
