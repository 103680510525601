.link {
	background-color: white;
	padding: 0.3rem 0.2rem 0.3rem 0.2rem;
	border-radius: 0.5rem;
}

.link:hover,
.link:hover a {
	background-color: #e0e3e5;
	color: #67737e;
}

.link:active,
.link a:active {
	background-color: #ffd8ab;
	color: #8f99a3;
}

.link a {
	color: #8f99a3;
	text-decoration: none;
}

.selected {
	color: #232323 !important;
}
