@use '../../styles/variables' as *;

.dso_input_cont {
	position: relative;
	&.dso_input_error {
		.dso_input_subtitle {
			color: $red_300;
		}
		.dso_input,
		.dso_input:focus {
			border-color: $red_300;
		}
	}
	.dso_input_title {
		margin-bottom: $md;
		display: flex;
		.dso_input_text {
			line-height: $lg;
			font-size: 0.875rem;
			font-weight: 800;
			color: $neutral_700;
		}
		.dso_input_img {
			margin-right: $xs;
			width: $lg;
			height: $lg;
			fill: $primary_300;
			& > * {
				width: 100%;
				height: 100%;
			}
		}
	}
	.dso_input {
		box-shadow: $shadow_soft_inv;
		width: 100%;
		border: 1px solid $neutral_500;
		border-radius: $xxs;
		outline: none;
		padding: 0.625rem $md;
		line-height: 1.25rem;
		font-size: 0.875rem;
		font-weight: 800;
		color: $neutral_700;
		&:focus {
			border-color: $primary_300;
		}
		&::placeholder {
			color: $neutral_500;
		}
	}
	.dso_input_select_cont {
		position: relative;
		width: 100%;
	}
	.dso_input_subtitle {
		margin-top: $md;
		line-height: $md;
		font-size: $sm;
		font-weight: 400;
		color: $neutral_500;
	}
}

.dso_input_checkbox_group {
	padding-top: $xs;
}
.dso_input_checkbox_cont {
	display: block;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	margin-bottom: $lg;
	padding-left: $xl;
	color: $neutral_700;
	position: relative;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked ~ .dso_input_checkbox {
		border-color: $primary_300;
		background-color: $primary_300;
		.dso_input_checkbox_icon {
			display: unset;
		}
		}
	}
	.dso_input_checkbox {
		&.big{
		height: 1.25rem;
		width: 1.25rem;
		.dso_input_checkbox_icon {
			height: $md;
		width: $md;
		}
		}
		position: absolute;
		left: 0;
		height: $md;
		width: $md;
		border: 1px solid $neutral_800;
		background-color: $neutral_0;
		.dso_input_checkbox_icon {
		display: none;
		position: absolute;
		width: $sm;
		height: $sm;
		fill: $neutral_0;
		stroke: transparent;
		}
	}
}

.dso_input_slider_cont {
	display: block;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	color: $neutral_700;
	position: relative;
	width: fit-content;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 100%;
		width: 100%;
		z-index: 1;
		&.false {
			pointer-events: none;
		}
		&.true:checked {
			pointer-events: none;
			& ~ .false {
				pointer-events: all;
			}
			& ~ .dso_input_slider {
				background-color: $primary_300;
				.dso_input_slider_ball {
					left: 3.8125rem;
				}
				.no {
					color: white;
				}
			}
		}
	}
	&.labels .dso_input_slider .dso_input_slider_label {
		display: unset;
	}
	.dso_input_slider {
		position: relative;
		left: 0;
		height: 2.375rem;
		width: 6.125rem;
		border: 1px solid $neutral_500;
		background-color: $neutral_500;
		transition: 0.5s;
		border-radius: 1.25rem;

		.dso_input_slider_ball {
			height: $xxl;
			transition: 0.125s;
			width: $xxl;
			border-radius: 50%;
			position: absolute;
			top: -1px;
			left: -1px;
			background-color: white;
		}
		.dso_input_slider_label {
			position: absolute;
			display: none;
			z-index: 1;
			&.no {
				left: 1.25rem;
			}
			&.si {
				left: calc(5rem + 1px);
			}
		}
	}
}
.dso_input_radio_group {
	padding-top: $xs;
	.dso_input_radio_cont {
		display: block;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		cursor: pointer;
		margin-bottom: $lg;
		padding-left: $xl;
		color: $neutral_700;
		position: relative;
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
			&:checked ~ .dso_input_radio {
				border-color: $primary_300;
				.dso_input_radio_icon {
					display: unset;
				}
			}
		}
		.dso_input_radio {
			position: absolute;
			left: 0;
			height: $md;
			width: $md;
			border: 1px solid $neutral_800;
			border-radius: 50%;
			.dso_input_radio_icon {
				display: none;
				position: absolute;
				width: $xs;
				height: $xs;
				border-radius: 50%;
				background: $primary_300;
			}
		}
	}
}

.dso_short_date_cont {
	padding: 0.5rem 0rem;
	user-select: none;
	* {
		user-select: none;
		outline: none;
	}
}
