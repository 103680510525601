.detail_data {
	border: 1px var(--neutral_300) solid;
	border-width: 1px 1px 0;
	& > *:first-child {
		border-right: 1px var(--neutral_300) solid;
	}
}
.final_detail {
	border-top: 1px var(--neutral_300) solid;
}
.break_word {
	overflow-wrap: break-word;
}
